export const FirebaseConfig = {
	"projectId": "ono-cabs-app",
	"appId": "1:539576059044:web:f49d95ba095c01bed09231",
	"databaseURL": "https://ono-cabs-app-default-rtdb.firebaseio.com",
	"storageBucket": "ono-cabs-app.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCme-pBVnOU2rBBibWfkmc4q8XSOCPpNDo",
	"authDomain": "ono-cabs-app.firebaseapp.com",
	"messagingSenderId": "539576059044",
	"measurementId": "G-0P32H8J2PH"
};